.testclass {
    width: 30px;
    height: 30px;
}
.timestamp{
    font-size: 10px;
    color:grey;
    text-decoration: none;
    padding: 0.0rem 0.5rem;
    margin-bottom: 0rem;

}
.test p {
    text-align: center; 
    padding: 300px;
}
.test span { 
    background-color: green; 
    border-radius: 8px;
    font-size: 2rem;
    color: #fff;
    font-weight: 500;

}
.validateAllbtn{
    background-color: #008CBA; 
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}
.circlegreen {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border-radius: 50%;
    font-size: 19px;
    color: #fff;
    text-align: center;
    background: rgb(74, 197, 69);
    font-weight: 500;
  }
  .circlered {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border-radius: 50%;
    font-size: 19px;
    color: #fff;
    text-align: center;
    background: rgb(190, 40, 40);
    font-weight: 500;
  }
  .circleyellow {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border-radius: 50%;
    font-size: 19px;
    color: #fff;
    text-align: center;
    background: rgb(247, 205, 70);
    font-weight: 500;
  }
  .unpairbtn{
      border-radius: 50%;
      color: rgb(172, 169, 169);
      border: none;
      margin-left: 40px;
      font-size: 15px;
      padding-left: 7px;
      padding-right: 7px;
  }
.validation{
    position: relative;
    display: flex; 
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    flex-direction: row;
    justify-content: space-between;
}

.validationImgs{
    display: flex;
    flex-direction: row;
    align-items: flex-end;

}
.val-rightside{
    display: flex;
    flex-direction: column;
}
.buttons {
    text-align: center;
    background: white;
}

button:active {
  top: 1px;
  box-shadow: none;
}

.btn-green {
    border-radius: 50%;
    position: relative;
    width: 25px;
    height: 25px;
    text-align: center;
    color: rgb(172, 169, 169);
    border: none;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    
}
.btn-green .icon {
    width: 13px;
    height: 13px;
}

.tempp{
    color:red;
    padding: 0.0rem 0.5rem;
    font-size: 10px;
}
