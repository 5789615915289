@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
/*
    DEMO STYLE
*/


body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    display:inline; 
    float:left;
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.wrapper {
    display: flex;
    height: 900px;
    
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: rgb(30, 127, 195);
    color: #fff;
    transition: all 0.3s;
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: rgb(30, 127, 195);
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: red;
}


a[data-toggle="collapse"] {
    position: relative;
}

a[aria-expanded="false"]::before, a[aria-expanded="true"]::before {
    content: '\e259';
    display: block;
    position: absolute;
    right: 20px;
    font-family: 'Glyphicons Halflings';
    font-size: 0.6em;
}
a[aria-expanded="true"]::before {
    content: '\e260';
}


ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #eaf0f4;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article, a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}




/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
    padding: 20px;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
}
.logoimg{
    width: 50%;
    height: 50%;
}
.buttonFrontPage {
    display:inline; 
    width: 500px;
    height: 70px;
    padding: 10px;
    line-height: 50px;
    text-align: left;
    text-indent: 10px;
    font-family: Verdana, Geneva, sans-serif;
    font-size: 16px;
    color: #333;
    border-radius: 5px;
    border: 1px solid #333;
    background-color: white;
    cursor: pointer;
    margin: 20px;
    float:left;
}

.buttonFrontPage:hover {
    background-color: #F7F7F7;
}

.buttonFrontPage img {
    max-width: 80%;
    max-height: 80%;
    float: left;
}

.testclass {
    width: 30px;
    height: 30px;
}
.timestamp{
    font-size: 10px;
    color:grey;
    text-decoration: none;
    padding: 0.0rem 0.5rem;
    margin-bottom: 0rem;

}
.test p {
    text-align: center; 
    padding: 300px;
}
.test span { 
    background-color: green; 
    border-radius: 8px;
    font-size: 2rem;
    color: #fff;
    font-weight: 500;

}
.validateAllbtn{
    background-color: #008CBA; 
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}
.circlegreen {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border-radius: 50%;
    font-size: 19px;
    color: #fff;
    text-align: center;
    background: rgb(74, 197, 69);
    font-weight: 500;
  }
  .circlered {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border-radius: 50%;
    font-size: 19px;
    color: #fff;
    text-align: center;
    background: rgb(190, 40, 40);
    font-weight: 500;
  }
  .circleyellow {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border-radius: 50%;
    font-size: 19px;
    color: #fff;
    text-align: center;
    background: rgb(247, 205, 70);
    font-weight: 500;
  }
  .unpairbtn{
      border-radius: 50%;
      color: rgb(172, 169, 169);
      border: none;
      margin-left: 40px;
      font-size: 15px;
      padding-left: 7px;
      padding-right: 7px;
  }
.validation{
    position: relative;
    display: flex; 
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    flex-direction: row;
    justify-content: space-between;
}

.validationImgs{
    display: flex;
    flex-direction: row;
    align-items: flex-end;

}
.val-rightside{
    display: flex;
    flex-direction: column;
}
.buttons {
    text-align: center;
    background: white;
}

button:active {
  top: 1px;
  box-shadow: none;
}

.btn-green {
    border-radius: 50%;
    position: relative;
    width: 25px;
    height: 25px;
    text-align: center;
    color: rgb(172, 169, 169);
    border: none;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    
}
.btn-green .icon {
    width: 13px;
    height: 13px;
}

.tempp{
    color:red;
    padding: 0.0rem 0.5rem;
    font-size: 10px;
}

.inputform{
    width: 400px;
    padding-left: 20px;
}

.alertbox {
    padding: 20px;
    background-color: #2a76cc; /* Red */
    color: white;
    width: 100%;
    font-size: 12px;  }
  
  /* The close button */
  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .addButton{      
      width: 100%;
      padding: 8px;
      background-color: #4CAF50;
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      
  }
  .unpButton{
    width: 100%;
    padding: 8px;
    background-color: #f44336; 
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
  


                
body{margin-top:20px;}              
.modal-footer {   border-top: 0px; }
                                    
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eaf0f4;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html{
  min-height: 900px;
}
