.buttonFrontPage {
    display:inline; 
    width: 500px;
    height: 70px;
    padding: 10px;
    line-height: 50px;
    text-align: left;
    text-indent: 10px;
    font-family: Verdana, Geneva, sans-serif;
    font-size: 16px;
    color: #333;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #333;
    background-color: white;
    cursor: pointer;
    margin: 20px;
    float:left;
}

.buttonFrontPage:hover {
    background-color: #F7F7F7;
}

.buttonFrontPage img {
    max-width: 80%;
    max-height: 80%;
    float: left;
}
