.inputform{
    width: 400px;
    padding-left: 20px;
}

.alertbox {
    padding: 20px;
    background-color: #2a76cc; /* Red */
    color: white;
    width: 100%;
    font-size: 12px;  }
  
  /* The close button */
  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .addButton{      
      width: 100%;
      padding: 8px;
      background-color: #4CAF50;
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      
  }
  .unpButton{
    width: 100%;
    padding: 8px;
    background-color: #f44336; 
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
  
